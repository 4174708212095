import React, { FunctionComponent } from 'react';

const SvgCheckmark: FunctionComponent = () => (
  <>
    {/* language=CSS */}
    <style jsx>{`
      @-webkit-keyframes checkmark {
        0% {
          stroke-dashoffset: 50px;
        }
        100% {
          stroke-dashoffset: 0;
        }
      }
      @-ms-keyframes checkmark {
        0% {
          stroke-dashoffset: 50px;
        }
        100% {
          stroke-dashoffset: 0;
        }
      }
      @keyframes checkmark {
        0% {
          stroke-dashoffset: 50px;
        }
        100% {
          stroke-dashoffset: 0;
        }
      }

      @-webkit-keyframes checkmark-circle {
        0% {
          stroke-dashoffset: 240px;
        }
        100% {
          stroke-dashoffset: 480px;
        }
      }
      @-ms-keyframes checkmark-circle {
        0% {
          stroke-dashoffset: 240px;
        }
        100% {
          stroke-dashoffset: 480px;
        }
      }
      @keyframes checkmark-circle {
        0% {
          stroke-dashoffset: 240px;
        }
        100% {
          stroke-dashoffset: 480px;
        }
      }

      svg circle {
        -webkit-animation: checkmark-circle 0.6s ease-in-out backwards;
        animation: checkmark-circle 0.6s ease-in-out backwards;
        stroke-dasharray: 240px, 240px;
        stroke-dashoffset: 480px;
      }

      svg path {
        -webkit-animation: checkmark 0.25s ease-in-out 0.7s backwards;
        animation: checkmark 0.25s ease-in-out 0.7s backwards;
        stroke-dasharray: 50px, 50px;
        stroke-dashoffset: 0;
      }
    `}</style>
    <svg xmlns="http://www.w3.org/2000/svg" width="72px" height="72px">
      <g fill="none" stroke="#8EC343" strokeWidth="2">
        <circle cx="36" cy="36" r="35" />
        <path d="M17.417,37.778l9.93,9.909l25.444-25.393" />
      </g>
    </svg>
  </>
);

export default SvgCheckmark;
