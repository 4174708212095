import React, { FunctionComponent, ReactNode } from 'react';
import { Card } from 'react-bootstrap';

import styles from './Alert.module.css';

type Props = {
  children: ReactNode;
};

const Alert: FunctionComponent<Props> = ({ children }) => (
  <div className={styles.wrapper}>
    <div className={styles.background} />
    <Card>
      <Card.Body className={styles.card}>{children}</Card.Body>
    </Card>
  </div>
);

export default Alert;
