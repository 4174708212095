import { useRouter } from 'next/router';

const useLang = (): string => {
  const router = useRouter();

  if (router.asPath === '/') {
    return 'en';
  }
  if (router.asPath === '/es') {
    return 'es';
  }

  return '';
};

export default useLang;
