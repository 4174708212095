import classnames from 'classnames';
import Link from 'next/link';
import React, { FunctionComponent } from 'react';

import useLang from '../lib/useLang';
import styles from './LangSwitch.module.css';

const LangSwitch: FunctionComponent = () => {
  const activeLang = useLang();

  return (
    <div className={styles.container}>
      <Link href="/">
        <a>
          <img className={classnames({ [styles.active]: activeLang === 'en' })} src="/img/flag-en.png" alt="US flag" />
        </a>
      </Link>
      <Link href="/es">
        <a>
          <img
            className={classnames({ [styles.active]: activeLang === 'es' })}
            src="/img/flag-es.png"
            alt="Spain flag"
          />
        </a>
      </Link>
    </div>
  );
};

export default LangSwitch;
