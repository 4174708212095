/*
 * based on:
 *   - https://www.robinwieruch.de/react-hooks-fetch-data
 *   - https://www.sumologic.com/blog/react-hook-typescript/
 *   - https://stackoverflow.com/a/55398709/368026
 *   - https://stackoverflow.com/a/56263257/368026
 */

import { useReducer } from 'react';

export const FETCH_STATUS_READY = 'ready';
export const FETCH_STATUS_LOADING = 'loading';
export const FETCH_STATUS_SUCCEEDED = 'succeeded';
export const FETCH_STATUS_FAILED = 'failed';

type Action<T> = { type: 'START' } | { type: 'SUCCEEDED'; payload: T } | { type: 'FAILED'; error: string };

type FetchState<T> =
  | { status: typeof FETCH_STATUS_READY; data: null; error: null }
  | { status: typeof FETCH_STATUS_LOADING; data: null; error: null }
  | { status: typeof FETCH_STATUS_SUCCEEDED; data: T | null; error: null }
  | { status: typeof FETCH_STATUS_FAILED; data: null; error: string };

const createReducer = <T>() => (state: FetchState<T>, action: Action<T>): FetchState<T> => {
  switch (action.type) {
    case 'START':
      return { status: FETCH_STATUS_LOADING, data: null, error: null };
    case 'SUCCEEDED':
      return { status: FETCH_STATUS_SUCCEEDED, data: action.payload, error: null };
    case 'FAILED':
      return { status: FETCH_STATUS_FAILED, data: null, error: action.error };
    default:
      throw new Error();
  }
};

const useFetch = <T>(
  endpoint: string,
  method: 'GET' | 'POST' | 'PUT' | 'DELETE' = 'GET',
): [T | null, string, string | null, (body?: string[] | Record<string, unknown>) => void] => {
  const [{ data, status, error }, dispatch] = useReducer(createReducer<T>(), {
    data: null,
    error: null,
    status: FETCH_STATUS_READY,
  });

  const makeReq = async (body?: string[] | Record<string, unknown>) => {
    dispatch({ type: 'START' });

    try {
      const res = await fetch(`/api${endpoint}`, {
        method,
        headers: { 'Content-Type': 'application/json' },
        ...(body != null && { body: JSON.stringify(body) }),
      });
      const payload = await res.json();

      if (!res.ok) {
        const errorMessage =
          payload.error || res?.statusText || (res?.status === 401 ? 'Unauthorized' : 'Server error');
        // noinspection ExceptionCaughtLocallyJS
        throw new Error(errorMessage);
      }

      dispatch({ type: 'SUCCEEDED', payload });
    } catch (err) {
      dispatch({ type: 'FAILED', error: err.message });
    }
  };

  return [data, status, error, makeReq];
};

export default useFetch;
