import { Field, FieldProps, Form as FormikForm, Formik } from 'formik';
import React, { FunctionComponent, RefObject, SyntheticEvent, useRef, useState } from 'react';
import { Button, Col, Form, Row } from 'react-bootstrap';
import FormCheck from 'react-bootstrap/FormCheck';
import FormCheckInput from 'react-bootstrap/FormCheckInput';
import FormCheckLabel from 'react-bootstrap/FormCheckLabel';
import FormControl from 'react-bootstrap/FormControl';
import { BiCheck, BiChevronLeft, BiChevronRight } from 'react-icons/bi';

import styles from './OnboardingForm.module.css';

type Props = {
  changeToPage: (page: 'GOTO_PAGE_1' | 'GOTO_PAGE_2' | 'GOTO_PAGE_3') => void;
  handleSubmit: (values: FormValues) => void;
  initialValues: FormValues;
  lang: string;
  openTosModal: (ev: SyntheticEvent) => void;
  page: number;
};

export type FormValues = {
  firstName: string;
  lastName: string;
  streetAddress1: string;
  streetAddress2: string;
  city: string;
  state: string;
  zipCode: string;
  mobilePhone: string;
  personalEmail: string;
  idType: string;
  idValue: string;
  birthDate: string;
  genderIdentification: string;
  raceEthnicIdentification: string;
  employeeEligibility: string;
  uscisNum: string;
  interestedInJob: string[];
  message: string;
  tos: boolean;
};

const ID_VAL_LENGTH = 9;

const OnboardingForm: FunctionComponent<Props> = ({
  changeToPage,
  handleSubmit,
  initialValues,
  lang,
  openTosModal,
  page,
}) => {
  const [idTypeLocal, setIdTypeLocal] = useState('');
  const idTypeFormControl = useRef() as RefObject<HTMLSelectElement>;

  const [birthDateFormControlType, setBirthDateFormControlType] = useState('text');
  const birthDateFormControl = useRef() as RefObject<HTMLInputElement>;

  const [employeeEligibilityLocal, setEmployeeEligibilityLocal] = useState('');
  const employeeEligibilityFormControl = useRef() as RefObject<HTMLSelectElement>;

  // eslint-disable-next-line consistent-return
  const validatePhoneNum = (value: string): string | void => {
    if (value.match(/\(\d{3}\) \d{3}-\d{4}/)) {
      return undefined;
    }
    if (value.match(/\D+/)) {
      return lang === 'es' ? '¡Solo puede ingresar números!' : 'You can enter only numbers!';
    }
    if (value.length < 10) {
      return lang === 'es' ? '¡Demasiados menos dígitos!' : 'Too less digits!';
    }
    if (value.length > 10) {
      return lang === 'es' ? '¡Demasiados dígitos!' : 'Too many digits!';
    }
  };

  const formatPhoneNumber = (value: string): string => {
    if (validatePhoneNum(value) != null) {
      return value;
    }

    if (value.indexOf('(') === 0) {
      return value;
    }

    const tokenized = value.split('');

    return `(${tokenized[0]}${tokenized[1]}${tokenized[2]}) ${tokenized[3]}${tokenized[4]}${tokenized[5]}-${tokenized[6]}${tokenized[7]}${tokenized[8]}${tokenized[9]}`;
  };

  const handleIdTypeChange = () => {
    if (idTypeFormControl?.current?.value != null) {
      setIdTypeLocal(idTypeFormControl?.current?.value);
    }
  };

  // eslint-disable-next-line consistent-return
  const validateIdValue = (value: string): string | void => {
    if (value.match(/\d{3}-\d{2}-\d{4}/)) {
      return undefined;
    }
    if (value.match(/\D+/)) {
      return lang === 'es' ? '¡Solo puede ingresar números!' : 'You can enter only numbers!';
    }
    if (value.length < ID_VAL_LENGTH) {
      return lang === 'es' ? '¡Demasiados menos dígitos!' : 'Too less digits!';
    }
    if (value.length > ID_VAL_LENGTH) {
      return lang === 'es' ? '¡Demasiados dígitos!' : 'Too many digits!';
    }
  };

  const formatIdValue = (value: string): string => {
    if (validateIdValue(value) != null) {
      return value;
    }

    if (value.indexOf('-') === 3) {
      return value;
    }

    const tokenized = value.split('');

    return `${tokenized[0]}${tokenized[1]}${tokenized[2]}-${tokenized[3]}${tokenized[4]}-${tokenized[5]}${tokenized[6]}${tokenized[7]}${tokenized[8]}`;
  };

  const handleBirthDateFormControlFocus = () => {
    if (birthDateFormControlType !== 'date') {
      setBirthDateFormControlType('date');
      birthDateFormControl?.current?.blur();
      setTimeout(() => birthDateFormControl?.current?.focus());
    }
  };

  const handleEmployeeEligibilityChange = () => {
    if (employeeEligibilityFormControl?.current?.value != null) {
      setEmployeeEligibilityLocal(employeeEligibilityFormControl?.current?.value);
    }
  };

  return (
    <Formik initialValues={initialValues} onSubmit={handleSubmit} validateOnChange={false} validateOnBlur>
      {({ errors, setFieldValue }) => (
        <FormikForm>
          {page === 1 && (
            <>
              <Row>
                <Col>
                  <h2>
                    {lang === 'en' && 'Please fill with your details'}
                    {lang === 'es' && 'Por favor, de rellenar con sus datos'}
                  </h2>
                  <span className={styles.progressIndicator}>1/3</span>
                </Col>
              </Row>

              <Form.Row>
                <Col>
                  <Field name="firstName">
                    {({ field }: FieldProps) => (
                      <FormControl
                        id="firstName"
                        onChange={field.onChange}
                        placeholder={((): string => {
                          if (lang === 'es') return 'Nombre';
                          return 'First name';
                        })()}
                        required
                        type="text"
                        value={field.value}
                      />
                    )}
                  </Field>
                </Col>
                <Col>
                  <Field name="lastName">
                    {({ field }: FieldProps) => (
                      <FormControl
                        id="lastName"
                        onChange={field.onChange}
                        placeholder={((): string => {
                          if (lang === 'es') return 'Apellido';
                          return 'Last name';
                        })()}
                        required
                        type="text"
                        value={field.value}
                      />
                    )}
                  </Field>
                </Col>
              </Form.Row>
              <Form.Row>
                <Col>
                  <Field name="streetAddress1">
                    {({ field }: FieldProps) => (
                      <FormControl
                        id="streetAddress1"
                        onChange={field.onChange}
                        placeholder={((): string => {
                          if (lang === 'es') return 'Domicilio 1';
                          return 'Street address 1';
                        })()}
                        required
                        type="text"
                        value={field.value}
                      />
                    )}
                  </Field>
                </Col>
                <Col>
                  <Field name="streetAddress2">
                    {({ field }: FieldProps) => (
                      <FormControl
                        id="streetAddress2"
                        onChange={field.onChange}
                        placeholder={((): string => {
                          if (lang === 'es') return 'Domicilio 2';
                          return 'Street address 2';
                        })()}
                        type="text"
                        value={field.value}
                      />
                    )}
                  </Field>
                </Col>
              </Form.Row>
              <Form.Row>
                <Col>
                  <Field name="city">
                    {({ field }: FieldProps) => (
                      <FormControl
                        id="city"
                        onChange={field.onChange}
                        placeholder={((): string => {
                          if (lang === 'es') return 'Ciudad';
                          return 'City';
                        })()}
                        required
                        type="text"
                        value={field.value}
                      />
                    )}
                  </Field>
                </Col>
                <Col md={{ span: 5 }}>
                  <Field name="state">
                    {({ field }: FieldProps) => (
                      <FormControl as="select" id="state" onChange={field.onChange} required value={field.value}>
                        {lang === 'en' && <option value="">Select your state...</option>}
                        {lang === 'es' && <option value="">Selecciona tu estado...</option>}
                        <option value="Alabama">Alabama</option>
                        <option value="Alaska">Alaska</option>
                        <option value="Arizona">Arizona</option>
                        <option value="Arkansas">Arkansas</option>
                        <option value="California">California</option>
                        <option value="Colorado">Colorado</option>
                        <option value="Connecticut">Connecticut</option>
                        <option value="Delaware">Delaware</option>
                        <option value="Florida">Florida</option>
                        <option value="Georgia">Georgia</option>
                        <option value="Hawaii">Hawaii</option>
                        <option value="Idaho">Idaho</option>
                        <option value="Illinois">Illinois</option>
                        <option value="Indiana">Indiana</option>
                        <option value="Iowa">Iowa</option>
                        <option value="Kansas">Kansas</option>
                        <option value="Kentucky">Kentucky</option>
                        <option value="Louisiana">Louisiana</option>
                        <option value="Maine">Maine</option>
                        <option value="Maryland">Maryland</option>
                        <option value="Massachusetts">Massachusetts</option>
                        <option value="Michigan">Michigan</option>
                        <option value="Minnesota">Minnesota</option>
                        <option value="Mississippi">Mississippi</option>
                        <option value="Missouri">Missouri</option>
                        <option value="Montana">Montana</option>
                        <option value="Nebraska">Nebraska</option>
                        <option value="Nevada">Nevada</option>
                        <option value="New Hampshire">New Hampshire</option>
                        <option value="New Jersey">New Jersey</option>
                        <option value="New Mexico">New Mexico</option>
                        <option value="New York">New York</option>
                        <option value="North Carolina">North Carolina</option>
                        <option value="North Dakota">North Dakota</option>
                        <option value="Ohio">Ohio</option>
                        <option value="Oklahoma">Oklahoma</option>
                        <option value="Oregon">Oregon</option>
                        <option value="Pennsylvania">Pennsylvania</option>
                        <option value="Rhode Island">Rhode Island</option>
                        <option value="South Carolina">South Carolina</option>
                        <option value="South Dakota">South Dakota</option>
                        <option value="Tennessee">Tennessee</option>
                        <option value="Texas">Texas</option>
                        <option value="Utah">Utah</option>
                        <option value="Vermont">Vermont</option>
                        <option value="Virginia">Virginia</option>
                        <option value="Washington">Washington</option>
                        <option value="West Virginia">West Virginia</option>
                        <option value="Wisconsin">Wisconsin</option>
                        <option value="Wyoming">Wyoming</option>
                      </FormControl>
                    )}
                  </Field>
                </Col>
                <Col>
                  <Field name="zipCode">
                    {({ field }: FieldProps) => (
                      <FormControl
                        id="zipCode"
                        onChange={field.onChange}
                        pattern="[0-9\s]*"
                        placeholder={((): string => {
                          if (lang === 'es') return 'Codigo Postal';
                          return 'ZIP Code';
                        })()}
                        required
                        type="text"
                        value={field.value}
                      />
                    )}
                  </Field>
                </Col>
              </Form.Row>
              <Form.Row>
                <Form.Group as={Col}>
                  <Field name="mobilePhone" validate={validatePhoneNum}>
                    {({ field }: FieldProps) => (
                      <>
                        <FormControl
                          id="mobilePhone"
                          isInvalid={!!errors.mobilePhone}
                          maxLength={10}
                          onBlur={(ev: React.FocusEvent) => {
                            setFieldValue('mobilePhone', formatPhoneNumber(field.value), false);
                            field.onBlur(ev);
                          }}
                          onChange={field.onChange}
                          placeholder={((): string => {
                            if (lang === 'es') return 'Numero de telefono celular';
                            return 'Mobile phone #';
                          })()}
                          required
                          type="tel"
                          value={field.value}
                        />
                        <FormControl.Feedback type="invalid" tooltip>
                          {errors.mobilePhone}
                        </FormControl.Feedback>
                      </>
                    )}
                  </Field>
                </Form.Group>
                <Col>
                  <Field name="personalEmail">
                    {({ field }: FieldProps) => (
                      <FormControl
                        id="personalEmail"
                        onChange={field.onChange}
                        placeholder={((): string => {
                          if (lang === 'es') return 'Correo electrónico personal';
                          return 'Personal email';
                        })()}
                        required
                        type="email"
                        value={field.value}
                      />
                    )}
                  </Field>
                </Col>
              </Form.Row>

              <div className={styles.spacer1} />
              <Form.Row>
                <Col className={styles.actions}>
                  <Button type="submit">
                    Próxima <BiChevronRight />
                  </Button>
                </Col>
              </Form.Row>
            </>
          )}

          {page === 2 && (
            <>
              <Row>
                <Col>
                  <h2>
                    {lang === 'en' && 'Please fill with additional info'}
                    {lang === 'es' && 'Por favor, de rellenar con sus datos'}
                  </h2>
                  <span className={styles.progressIndicator}>2/3</span>
                </Col>
              </Row>

              <Form.Row>
                <Col md={{ span: idTypeLocal === '' ? 12 : 4 }}>
                  <Field name="idType">
                    {({ field }: FieldProps) => (
                      <FormControl
                        as="select"
                        id="idType"
                        onChange={(ev: React.ChangeEvent) => {
                          field.onChange(ev);
                          handleIdTypeChange();
                        }}
                        ref={idTypeFormControl}
                        required
                        value={field.value}
                      >
                        {lang === 'en' && <option value="">ID Type...</option>}
                        {lang === 'es' && <option value="">Tipo de identificación...</option>}
                        <option value="SSN">{idTypeLocal === 'SSN' ? 'SSN' : `SSN (xxx-xx-xxx)`}</option>
                        <option value="ITIN">{idTypeLocal === 'ITIN' ? 'ITIN' : `ITIN (xxx-xx-xxx)`}</option>
                      </FormControl>
                    )}
                  </Field>
                </Col>
                <Col style={{ display: idTypeLocal === '' ? 'none' : undefined }}>
                  <Field name="idValue" validate={validateIdValue}>
                    {({ field }: FieldProps) => (
                      <>
                        <FormControl
                          id="idValue"
                          isInvalid={!!errors.idValue}
                          maxLength={ID_VAL_LENGTH}
                          onBlur={(ev: React.FocusEvent) => {
                            setFieldValue('idValue', formatIdValue(field.value), false);
                            field.onBlur(ev);
                          }}
                          onChange={field.onChange}
                          placeholder="xxx-xx-xxx"
                          required
                          type="text"
                          value={field.value}
                        />
                        <FormControl.Feedback type="invalid" tooltip>
                          {errors.idValue}
                        </FormControl.Feedback>
                      </>
                    )}
                  </Field>
                </Col>
              </Form.Row>
              <Form.Row>
                <Col>
                  <Field name="birthDate">
                    {({ field }: FieldProps) => (
                      <FormControl
                        id="birthDate"
                        onChange={field.onChange}
                        onFocus={handleBirthDateFormControlFocus}
                        placeholder={((): string => {
                          if (lang === 'es') return 'Fecha de nacimiento';
                          return 'Birth date';
                        })()}
                        ref={birthDateFormControl}
                        required
                        max="2002-12-31"
                        type={birthDateFormControlType}
                        value={field.value}
                      />
                    )}
                  </Field>
                </Col>
              </Form.Row>
              <Form.Row>
                <Col>
                  <Field name="genderIdentification">
                    {({ field }: FieldProps) => (
                      <FormControl
                        as="select"
                        id="genderIdentification"
                        onChange={field.onChange}
                        required
                        value={field.value}
                      >
                        {lang === 'en' && (
                          <>
                            <option value="">Gender Identification...</option>
                            <option value="Male">Male</option>
                            <option value="Female">Female</option>
                            <option value="I do not wish to answer">I do not wish to answer</option>
                          </>
                        )}
                        {lang === 'es' && (
                          <>
                            <option value="">Identificación de género...</option>
                            <option value="Male">Masculino</option>
                            <option value="Female">Femenino</option>
                            <option value="I do not wish to answer">No deseo contestar la pregunta</option>
                          </>
                        )}
                      </FormControl>
                    )}
                  </Field>
                </Col>
                <Col>
                  <Field name="raceEthnicIdentification">
                    {({ field }: FieldProps) => (
                      <FormControl
                        as="select"
                        id="raceEthnicIdentification"
                        onChange={field.onChange}
                        required
                        value={field.value}
                      >
                        {lang === 'en' && (
                          <>
                            <option value="">Race/Ethnic Identification...</option>
                            <option value="Hispanic or Latino">Hispanic or Latino</option>
                            <option value="White (Not Hispanic or Latino)">White (Not Hispanic or Latino)</option>
                            <option value="Black or African American (Not Hispanic or Latino)">
                              Black or African American (Not Hispanic or Latino)
                            </option>
                            <option value="Native Hawaiian or Pacific Islander (Not Hispanic or Latino)">
                              Native Hawaiian or Pacific Islander (Not Hispanic or Latino)
                            </option>
                            <option value="Asian (Not Hispanic or Latino)">Asian (Not Hispanic or Latino)</option>
                            <option value="Native American or Alaska Native (Not Hispanic or Latino)">
                              Native American or Alaska Native (Not Hispanic or Latino)
                            </option>
                            <option value="Two or More Races (Not Hispanic or Latino)">
                              Two or More Races (Not Hispanic or Latino)
                            </option>
                            <option value="I do not wish to disclose">I do not wish to disclose</option>
                          </>
                        )}
                        {lang === 'es' && (
                          <>
                            <option value="">Identificar Raza/etnico...</option>
                            <option value="Hispanic or Latino">Hispano o latino</option>
                            <option value="White (Not Hispanic or Latino)">Blanco(No Hispano o Latino)</option>
                            <option value="Black or African American (Not Hispanic or Latino)">
                              Negro o Africano Americano:(No Hispano o Latino)
                            </option>
                            <option value="Native Hawaiian or Pacific Islander (Not Hispanic or Latino)">
                              Nativo de Hawai o de las islas del pacifico(No Hispano o Latino
                            </option>
                            <option value="Asian (Not Hispanic or Latino)">Asiatico(No Hispano o Latino)</option>
                            <option value="Native American or Alaska Native (Not Hispanic or Latino)">
                              Nativo Americano o Nativo de Alaska( no Hispano Latino)
                            </option>
                            <option value="I do not wish to disclose">No deseo contestar la pregunta</option>
                          </>
                        )}
                      </FormControl>
                    )}
                  </Field>
                </Col>
              </Form.Row>
              <Form.Row>
                <Col>
                  <Field name="employeeEligibility">
                    {({ field }: FieldProps) => (
                      <FormControl
                        as="select"
                        id="employeeEligibility"
                        onChange={(ev) => {
                          field.onChange(ev);
                          handleEmployeeEligibilityChange();
                        }}
                        ref={employeeEligibilityFormControl}
                        required
                        value={field.value}
                      >
                        {lang === 'en' && (
                          <>
                            <option value="">Employee Eligibility...</option>
                            <option value="A citizen of the United States">A citizen of the United States</option>
                            <option value="A non-citizen national of the United States">
                              A non-citizen national of the United States (See instructions)
                            </option>
                            <option value="A lawful permanent resident">A lawful permanent resident (UCIS #)</option>
                            <option value="An alien authorized to work">An alien authorized to work</option>
                          </>
                        )}
                        {lang === 'es' && (
                          <>
                            <option value="">Elegibilidad de empleo...</option>
                            <option value="A citizen of the United States">Ciudadano de los Estados Unidos</option>
                            <option value="A non-citizen national of the United States">
                              Nacionales no ciudadanos de los Estados Unidos
                            </option>
                            <option value="A lawful permanent resident">
                              Un permanente legal de los Estados Unidos(UCIS#)
                            </option>
                            <option value="An alien authorized to work">Un extranjero autorizado para trabajar</option>
                          </>
                        )}
                      </FormControl>
                    )}
                  </Field>
                </Col>
                <Col
                  md={{ span: 5 }}
                  style={{ display: employeeEligibilityLocal === 'A lawful permanent resident' ? undefined : 'none' }}
                >
                  <Field name="uscisNum">
                    {({ field }: FieldProps) => (
                      <FormControl
                        id="uscisNum"
                        onChange={field.onChange}
                        placeholder="USCIS number"
                        required={employeeEligibilityLocal === 'A lawful permanent resident'}
                        type="text"
                        value={field.value}
                      />
                    )}
                  </Field>
                </Col>
              </Form.Row>
              <Form.Row>
                <Col>
                  <Form.Group>
                    <Form.Label>
                      {lang === 'en' && (
                        <>
                          Interested in Job Positions... <small>(can select multiple)</small>
                        </>
                      )}
                      {lang === 'es' && (
                        <>
                          Interesado en puestos de trabajo de <small>(puede seleccionar más de uno)</small>
                        </>
                      )}
                    </Form.Label>
                    <Field name="interestedInJob">
                      {({ field }: FieldProps) => (
                        <FormControl
                          as="select"
                          id="interestedInJob"
                          onChange={field.onChange}
                          required
                          multiple
                          value={field.value}
                        >
                          {lang === 'en' && (
                            <>
                              <option value="Cooking">Cooking</option>
                              <option value="Kitchen Preparation">Kitchen Preparation</option>
                              <option value="Truck Driving">Truck Driving</option>
                              <option value="Last Mile Delivery">Last Mile Delivery (Must own vehicle)</option>
                              <option value="Dishwasher">Dishwasher</option>
                              <option value="Porter">Porter (General Cleaning)</option>
                              <option value="Receiving">Receiving</option>
                            </>
                          )}
                          {lang === 'es' && (
                            <>
                              <option value="Cooking">Cocinar</option>
                              <option value="Kitchen Preparation">Preparador de cocina</option>
                              <option value="Truck Driving">Chofer de Camiones</option>
                              <option value="Last Mile Delivery">
                                Entrega de última Milla( Tiene que tener su propio carro)
                              </option>
                              <option value="Dishwasher">Lavaplatos</option>
                              <option value="Porter">Portero(limpieza general)</option>
                              <option value="Receiving">Receptacíon</option>
                            </>
                          )}
                        </FormControl>
                      )}
                    </Field>
                  </Form.Group>
                </Col>
              </Form.Row>

              <Form.Row>
                <Col className={styles.actions}>
                  <Button onClick={() => changeToPage('GOTO_PAGE_1')} variant="link">
                    <BiChevronLeft /> Atrás
                  </Button>
                  <Button type="submit">
                    Próxima <BiChevronRight />
                  </Button>
                </Col>
              </Form.Row>
            </>
          )}

          {page === 3 && (
            <>
              <Row>
                <Col>
                  <h2>
                    {lang === 'en' && 'Send an optional message'}
                    {lang === 'es' && 'Mandar un mensaje opcional'}
                  </h2>
                  <span className={styles.progressIndicator}>3/3</span>
                </Col>
              </Row>

              <Form.Row>
                <Col>
                  <Field name="message">
                    {({ field }: FieldProps) => (
                      <FormControl
                        as="textarea"
                        id="message"
                        onChange={field.onChange}
                        placeholder={((): string => {
                          if (lang === 'es')
                            return 'Por favor de escribir los días y horas que está disponible para trabajar. También escriba cualquier otro comentario que quiera compartir con nosotros.';
                          return 'Please note your times of availability and any other notes you would like to share with us. Thank you!';
                        })()}
                        rows={7}
                        value={field.value}
                      />
                    )}
                  </Field>
                </Col>
              </Form.Row>
              <Form.Row>
                <Col>
                  <Field name="tos">
                    {({ field }: FieldProps) => (
                      <FormCheck type="checkbox">
                        <FormCheckInput
                          id="tos"
                          onChange={field.onChange}
                          required
                          type="checkbox"
                          checked={field.value}
                        />
                        {lang === 'en' && (
                          <FormCheckLabel>
                            Please accept{' '}
                            <span
                              onClick={openTosModal}
                              onKeyPress={openTosModal}
                              role="button"
                              tabIndex={0}
                              className={styles.linkLike}
                            >
                              terms and conditions
                            </span>
                            .
                          </FormCheckLabel>
                        )}
                        {lang === 'es' && (
                          <FormCheckLabel>
                            Por favor de aceptar{' '}
                            <span
                              onClick={openTosModal}
                              onKeyPress={openTosModal}
                              role="button"
                              tabIndex={0}
                              className={styles.linkLike}
                            >
                              los términos y condiciones.
                            </span>
                            .
                          </FormCheckLabel>
                        )}
                      </FormCheck>
                    )}
                  </Field>
                </Col>
              </Form.Row>

              <div className={styles.spacer3} />
              <Form.Row>
                <Col className={styles.actions}>
                  <Button onClick={() => changeToPage('GOTO_PAGE_2')} variant="link">
                    <BiChevronLeft /> Atrás
                  </Button>
                  <Button type="submit">
                    Enviar <BiCheck />
                  </Button>
                </Col>
              </Form.Row>
            </>
          )}
        </FormikForm>
      )}
    </Formik>
  );
};

export default OnboardingForm;
